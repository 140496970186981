import { ModuleWithProviders } from '@angular/core';
import { environment } from '@ion/env';
import {
  ApiModule as AfbV2ApiModule,
  Configuration as AfbV2Configuration,
} from '@ionity/access-fee-service-v2-frontend-typings';
import {
  ApiModule as AlertingApiModule,
  Configuration as AlertingConfiguration,
} from '@ionity/alerting-service-frontend-typings';
import {
  ApiModule as AIApiModule,
  Configuration as AIApiConfiguration,
} from '@ionity/asset-information-service-frontend-typings';
import {
  ApiModule as AuditApiModule,
  Configuration as AuditApiConfiguration,
} from '@ionity/audit-service-frontend-typings';
import {
  ApiModule as AuthApiModule,
  Configuration as AuthApiConfiguration,
} from '@ionity/auth-service-frontend-typings';
import { ApiModule as BupaApiModule, Configuration as BupaApiConfiguration } from '@ionity/bupa-frontend-typings';
import {
  ApiModule as BupaV2ApiModule,
  Configuration as BupaV2ApiConfiguration,
} from '@ionity/bupa-v2-frontend-typings';
import {
  ApiModule as CertificateHandlerApiModule,
  Configuration as CertificateHandlerConfiguration,
} from '@ionity/certificate-handler-frontend-typings';
import { ApiModule as CcApiModule, Configuration as CcConfiguration } from '@ionity/charging-curve-frontend-typings';
import {
  ApiModule as MonApiModule,
  Configuration as MonApiConfiguration,
} from '@ionity/charging-monitor-frontend-typings';
import {
  ApiModule as CsaApiModule,
  Configuration as CsaConfiguration,
} from '@ionity/charging-service-authorization-frontend-typings';
import {
  ApiModule as CsmApiModule,
  Configuration as CsmConfiguration,
} from '@ionity/charging-session-management-frontend-typings';
import { ApiModule, Configuration } from '@ionity/cockpit-bff-frontend-typings';
import {
  ApiModule as CCGApiModule,
  Configuration as CCGApiConfiguration,
} from '@ionity/controller-communication-gateway-frontend-typings';
import {
  ApiModule as DqApiModule,
  Configuration as DqApiConfiguration,
} from '@ionity/data-quality-service-frontend-typings';
import {
  ApiModule as EmApiModule,
  Configuration as EmApiConfiguration,
} from '@ionity/energy-management-service-frontend-typings';
import {
  ApiModule as EvDriverApiModule,
  Configuration as EvDriverConfiguration,
} from '@ionity/ev-driver-frontend-typings';
import {
  ApiModule as FleetManagementApiModule,
  Configuration as FleetManagementApiConfiguration,
} from '@ionity/fleet-management-service-frontend-typings';
import {
  ApiModule as LoggingApiModule,
  Configuration as LoggingConfiguration,
} from '@ionity/logging-service-frontend-typings';
import {
  ApiModule as PoiApiModule,
  Configuration as PoiApiConfiguration,
} from '@ionity/point-of-interest-frontend-typings';
import {
  ApiModule as PricingEvDriverApiModule,
  Configuration as PricingEvDriverConfiguration,
} from '@ionity/pricing-ev-driver-frontend-typings';
import { ApiModule as PricingApiModule, Configuration as PricingConfiguration } from '@ionity/pricing-frontend-typings';
import {
  ApiModule as ProductsApiModule,
  Configuration as ProductsApiConfiguration,
} from '@ionity/products-service-frontend-typings';
import { ApiModule as QueueApiModule, Configuration as QueueConfiguration } from '@ionity/queue-frontend-typings';
import {
  ApiModule as RoamingApiModule,
  Configuration as RoamingApiConfiguration,
} from '@ionity/roaming-adapter-frontend-typings';
import {
  ApiModule as SafApiModule,
  Configuration as SafApiConfiguration,
} from '@ionity/search-and-find-frontend-typings';
import {
  ApiModule as SrmApiModule,
  Configuration as SrmConfiguration,
} from '@ionity/service-record-mediation-frontend-typings';
import {
  ApiModule as SdpApiModule,
  Configuration as SdpConfiguration,
} from '@ionity/signeddata-provisioning-frontend-typings';
import {
  ApiModule as SCGApiModule,
  Configuration as SCGApiConfiguration,
} from '@ionity/station-communication-gateway-frontend-typings';
import { ApiModule as SOApiModule, Configuration as SOConfiguration } from '@ionity/station-operation-frontend-typings';
import {
  ApiModule as SpaApiModule,
  Configuration as SpaApiConfiguration,
} from '@ionity/station-protocol-adapter-frontend-typings';
import {
  ApiModule as SprApiModule,
  Configuration as SprApiConfiguration,
} from '@ionity/subscription-products-frontend-typings';

/**
 * An array of all required API configurations from typings packages.
 *
 * @type {ModuleWithProviders<unknown>[]}
 */
export const apiConfigs: ModuleWithProviders<unknown>[] = [
  AIApiModule.forRoot(() => new AIApiConfiguration({ basePath: `${environment.apiUrl}/asset-information-service` })),
  AfbV2ApiModule.forRoot(() => new AfbV2Configuration({ basePath: `${environment.apiUrl}/access-fee-service-v2` })),
  AlertingApiModule.forRoot(() => new AlertingConfiguration({ basePath: `${environment.apiUrl}/alerting-service` })),
  ApiModule.forRoot(() => new Configuration({ basePath: environment.apiUrl })),
  AuditApiModule.forRoot(() => new AuditApiConfiguration({ basePath: `${environment.apiUrl}/audit-service` })),
  AuthApiModule.forRoot(() => new AuthApiConfiguration({ basePath: `${environment.apiUrl}/auth-service` })),
  BupaApiModule.forRoot(
    () => new BupaApiConfiguration({ basePath: `${environment.apiUrl}/business-partner-management` }),
  ),
  BupaV2ApiModule.forRoot(
    () => new BupaV2ApiConfiguration({ basePath: `${environment.apiUrl}/business-partner-management-v2` }),
  ),
  CcApiModule.forRoot(() => new CcConfiguration({ basePath: `${environment.apiUrl}/charging-curve` })),
  CertificateHandlerApiModule.forRoot(
    () => new CertificateHandlerConfiguration({ basePath: `${environment.apiUrl}/certificate-handler` }),
  ),
  CsaApiModule.forRoot(
    () => new CsaConfiguration({ basePath: `${environment.apiUrl}/charging-service-authorization` }),
  ),
  CsmApiModule.forRoot(() => new CsmConfiguration({ basePath: `${environment.apiUrl}/charging-session-management` })),
  EvDriverApiModule.forRoot(() => new EvDriverConfiguration({ basePath: `${environment.apiUrl}/ev-driver` })),
  FleetManagementApiModule.forRoot(
    () => new FleetManagementApiConfiguration({ basePath: `${environment.apiUrl}/fleet-management-service` }),
  ),
  LoggingApiModule.forRoot(() => new LoggingConfiguration({ basePath: `${environment.apiUrl}/logging-service` })),
  PoiApiModule.forRoot(() => new PoiApiConfiguration({ basePath: `${environment.apiUrl}/point-of-interest` })),
  PricingApiModule.forRoot(() => new PricingConfiguration({ basePath: `${environment.apiUrl}/pricing-service` })),
  PricingEvDriverApiModule.forRoot(
    () => new PricingEvDriverConfiguration({ basePath: `${environment.apiUrl}/pricing-ev-driver-service` }),
  ),
  ProductsApiModule.forRoot(() => new ProductsApiConfiguration({ basePath: `${environment.apiUrl}/products-service` })),
  QueueApiModule.forRoot(() => new QueueConfiguration({ basePath: `${environment.apiUrl}/queue` })),
  RoamingApiModule.forRoot(() => new RoamingApiConfiguration({ basePath: `${environment.apiUrl}/roaming` })),
  SCGApiModule.forRoot(
    () => new SCGApiConfiguration({ basePath: `${environment.apiUrl}/station-communication-gateway` }),
  ),
  SdpApiModule.forRoot(() => new SdpConfiguration({ basePath: `${environment.apiUrl}/signeddata-provisioning` })),
  SOApiModule.forRoot(() => new SOConfiguration({ basePath: `${environment.apiUrl}/station-operation` })),
  SpaApiModule.forRoot(() => new SpaApiConfiguration({ basePath: `${environment.apiUrl}/station-protocol-adapter` })),
  SprApiModule.forRoot(() => new SprApiConfiguration({ basePath: `${environment.apiUrl}/subscription-products` })),
  SrmApiModule.forRoot(() => new SrmConfiguration({ basePath: `${environment.apiUrl}/service-record-mediation` })),
  CCGApiModule.forRoot(
    () => new CCGApiConfiguration({ basePath: `${environment.apiUrl}/controller-communication-gateway` }),
  ),
  SafApiModule.forRoot(() => new SafApiConfiguration({ basePath: `${environment.apiUrl}/search-and-find` })),
  DqApiModule.forRoot(() => new DqApiConfiguration({ basePath: `${environment.apiUrl}/data-quality-service` })),
  MonApiModule.forRoot(() => new MonApiConfiguration({ basePath: `${getUrl('mon')}/charging-monitor` })),
  EmApiModule.forRoot(() => new EmApiConfiguration({ basePath: `${environment.apiUrl}/energy-management-service` })),
];

/**
 * Returns the API URL for the given key, falling back to the default URL or WireMock if mocked.
 *
 * @param {string} key - API key to lookup.
 * @returns {string} - Resolved API URL.
 */
function getUrl(key: string): string {
  const found = environment.serviceUrls.find(e => e.key === key);
  if (!found) return environment.apiUrl;
  return found.mock ? '/wiremock' : environment.apiUrl;
}
